<template>
  <div class="list-note margin-content">
    <div class="dc-header">
      <b-container
        fluid
        class="d-flex align-items-center"
        style="padding-left: 0px"
      >
        <router-link :to="{ name: 'library' }">
          <div>コンテンツ</div>
        </router-link>
        <b-icon icon="chevronRight" class="mx-3"></b-icon>
        <router-link :to="{ name: '' }">
          <div>ノート</div>
        </router-link>
      </b-container>
    </div>
    <div class="h2 mb-3 font-title">ノート一覧</div>
    <div class="list-note-content desktopNote" v-if="isShowDetailContent === 0">
      <div
        class="accordion justify-content-center"
        role="tablist"
        v-for="(item, index) in listContentShop.data"
        :key="index"
      >
        <div
          class="customTitle"
          v-bind:class="{ customBorder: index === 0 }"
          @click="showDetailContent(item.content_id)"
        >
          {{ item.title }}
        </div>
      </div>
      <div
        class="d-flex justify-content-center position-relative mt-3"
        v-if="listContentShop.last_page"
      >
        <CPagination
          v-if="listContentShop.total"
          :activePage.sync="page"
          :pages="listContentShop.last_page"
          align="center"
        />
      </div>
    </div>
    <div class="list-note-content mobile" v-if="isShowDetailContent === 0">
      <div
        class="accordion justify-content-center mt-3"
        role="tablist"
        v-for="(item, index) in listNoteShopSP.data"
        :key="index"
      >
        <div
          class="customTitleMoblie"
          v-bind:class="{ customBorder: index === 0 }"
          @click="showConfirmEdit(item.id)"
        >
          <div class="row mx-0">
            <div class="col-3 px-1">
              <div class="img" v-if="item.image_url">
                <img :src="env + item.image_url" />
              </div>
              <div class="img" v-else>
                <div class="h5 text-dark title-store title-note-content px-2">
                  {{ item.title }}
                </div>
              </div>
            </div>
            <div class="col-9 img_side_text">
              <div class="font-title-content title-note-content">
                {{ item.title }}
              </div>
              <div class="mt-3 font-title-note title-note-content">
                {{ item.title_note }}
              </div>
              <div class="mt-3 text-truncate-title">
                {{ item.content_note }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-center position-relative mt-3"
        v-if="listNoteShopSP.last_page"
      >
        <CPagination
          v-if="listNoteShopSP.total"
          :activePage.sync="pageSP"
          :pages="listNoteShopSP.last_page"
          align="center"
        />
      </div>
    </div>
    <div class="list-note-content" v-if="isShowDetailContent === 1">
      <div v-if="listNoteShopByContent <= 0">
        <span style="font-size: 17px"> ノートがありません </span>
      </div>
      <div
        class="title-content-list"
        v-for="(item, index) in listNoteShopByContent.data"
        :key="index"
      >
        <div class="title-content-list-item" style="word-wrap: break-word">
          <div class="header-item d-flex justify-content-between">
            <div
              class="header-item-title"
              style="word-break: break-all; padding-right: 3%"
            >
              {{ item.title_note }}
            </div>
            <div class="header-item-time text-nowrap">
              {{
                new Date(item.created_at).toLocaleString("ja-JP", {
                  timeZone: "Asia/Tokyo",
                })
              }}
              <a
                v-on:click="showConfirmEdit(item.id)"
                style="cursor: pointer"
                id="btn-show-note"
              >
                編集
              </a>
              <a
                v-on:click="showConfirm(item.id)"
                style="cursor: pointer"
                v-b-modal.delete-modal-page
              >
                削除
              </a>
            </div>
          </div>
          <div class="content-item customContentNote">
            <div v-html="item.content_note.replaceAll('\n', '<br />')"></div>
          </div>
          <!--<div class="content-item" style="font-size: 20px; font-weight: 600">
            PDF： {{ item.page_pdf }}枚目
          </div>
          <div class="content-item" style="font-size: 20px; font-weight: 600">
            動画： {{ item.seconds_watch_video }}秒
          </div>-->
          <div
            class="list-tags d-flex flex-wrap"
            style="padding: 0 50px 0 30px"
          >
            <div
              class="position-relative d-flex align-items-center px-1 py-1"
              v-for="(element, indexs) in item.tags"
              :key="indexs"
            >
              <a class="customA"
                ><span class="customSpan">{{ element.tag_name }}</span></a
              ><img
                src="@/assets/img/bg_note_tag_active.png"
                style="width: 18px; height: 20px"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="py-4 d-flex justify-content-center">
        <b-button
          class="mx-2 w-3 back-btn"
          block
          style="background: #e6e6e6; width: 30%"
          @click="backListContent()"
          >戻る</b-button
        >
      </div>
    </div>
    <div>
      <b-modal id="delete-modal-page" hide-header hide-footer>
        <div class="d-block text-center px-3 pt-1">
          <h5>
            削除後、ご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
          </h5>
        </div>
        <div class="d-flex justify-content-center px-3 pb-3">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            @click="removeNoteContent(dataModal)"
            >削除する</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-page')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
    <!-- Modal Edit Note -->
    <b-modal
      id="modal-edit-note"
      title="ノートを編集する
"
      class="modalCustom"
      style="min-width: 720px"
    >
      <template #modal-header="{}">
        <h5 class="w-100" style="text-align: center">ノート</h5>
        <b-button
          size="sm"
          style="background-color: white; border-color: white"
          class="close"
          @click="closeModalEdit()"
        >
          <i class="fas fa-times"></i>
        </b-button>
      </template>
      <CRow
        ><CCol sm="12">
          <input
            class="form-control hoverMouse"
            v-model="note.title_note"
            placeholder="タイトル"
            style="border: 1px solid #fff; height: 66px"
            :maxlength="50" /></CCol
        ><CCol sm="12" class="pt-3">
          <textarea
            class="form-input col-sm-12 p-3 hoverMouse"
            type="text"
            v-model="note.content_note"
            placeholder="メモ"
            rows="4"
            style="border: 1px solid #fff"
            :maxlength="1000"
          />
        </CCol>
        <!--<CCol sm="12" style="margin-bottom: 3%">
          <div class="d-flex align-items-center flex-nowrap">
            PDF： {{ noteById.page_pdf }} 枚目
          </div>
          <div class="d-flex align-items-center flex-nowrap">
            動画：{{ noteById.seconds_watch_video }} 秒
          </div>
        </CCol>-->
        <CCol sm="12">
          <input
            class="form-control hoverMouse"
            v-model="tag"
            placeholder="登録するタグ名を入力し[Enter]"
            style="border: 1px solid #fff; height: 64px"
            v-on:keyup.enter="createTag"
          /> </CCol
        ><CCol sm="12">
          <label class="title2">よく使うタグ</label>
          <div class="list-tag d-flex flex-wrap my-3 align-items-center">
            <div
              class="position-relative d-flex align-items-center px-1 py-1"
              v-for="(item, index) in listTagOptions"
              :key="index"
            >
              <a
                class="customTag2"
                :class="{ customA: item.checked === true }"
                v-on:click="ClickTagChecked(item, index)"
                ><span class="customSpan">{{ item.tag_name }}</span></a
              ><img
                v-if="item.checked === true"
                src="@/assets/img/bg_note_tag_active.png"
                style="width: 18px; height: 20px"
              />
              <img
                v-else
                src="@/assets/img/bg_note_tag.png"
                style="width: 18px; height: 20px"
              />
            </div>
            &ensp;&ensp;
          </div>
        </CCol>
      </CRow>
      <template #modal-footer="{}">
        <div class="d-flex justify-content-center my-4">
          <b-button variant="secondary" @click="closeModalEdit()" class=""
            >戻る</b-button
          >
          <b-button v-on:click.prevent="editNote()" variant="info" class=""
            >完了
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- Modal Edit Note -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Api } from "../../utils/http-common";
import { Urls } from "../../utils/urls";
import { Constants } from "../../utils/constants";
export default {
  name: "listNoteShop",
  data() {
    return {
      dataNote: [],
      title: "",
      selectedTag: [],
      listTagOptions: [],
      isLoading: false,
      tag: "",
      page: 1,
      isAdded: 0,
      isShowDetailContent: 0,
      listNoteShopByContent: null,
      idContent: null,
      env: Constants.URL_BE + "/storage/",
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      note: {},
      pageSP: 1,
    };
  },
  computed: {
    ...mapGetters([
      "listNoteShop",
      "listTag",
      "noteById",
      "message",
      "success",
      "error",
      "listContentShop",
      "IdContent",
      "listNoteShopSP",
    ]),
  },
  created() {
    this.getListNoteShopContents(1);
    this.getListTag(this.shop_id);
    const dataRequest = {
      page: this.pageSP,
      data: {
        shop_id: this.shop_id,
        limit: this.limit,
      },
    };
    this.getListNoteShopSP(dataRequest);
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listContentShop() {
      // change sentence
      let baseDefault = this.$root.$refs.baseDefault;
      this.listContentShop.data.forEach(function (item) {
        item.title = baseDefault.changeSentence(item.title);
      });
    },
    noteById() {
      this.note = this.noteById;
      this.selectedTag = this.noteById.tags;
      if (this.selectedTag.length > 0) {
        this.listTagOptions.forEach((element) => {
          this.selectedTag.forEach((item) => {
            if (element.tag_name === item.tag_name) {
              this.$set(element, "checked", true);
            }
          });
        });
      }
    },
    page() {
      this.getListNoteShopContents(this.page);
    },
    listNoteShop() {
      this.page = this.listNoteShop.current_page;
    },
    listTag() {
      this.listTagOptions = this.listTag.map((item) => ({
        tag_name: item.tag_name,
        tag_id: item.id,
      }));
      if (this.selectedTag.length > 0) {
        this.listTagOptions.forEach((element) => {
          this.selectedTag.forEach((item) => {
            if (element.tag_name === item.tag_name) {
              this.$set(element, "checked", true);
            }
          });
        });
      }
    },
    pageSP() {
      const dataRequest = {
        page: this.pageSP,
        data: {
          shop_id: this.shop_id,
          limit: this.limit,
        },
      };
      this.getListNoteShopSP(dataRequest);
    },
  },
  methods: {
    ...mapActions({
      getListNoteShopContent: "getListNoteShopContent",
      getListNoteShopByIdContent: "getListNoteShopByIdContent",
      deleteNoteContent: "deleteNoteContent",
      getNoteByIdUser: "getNoteByIdUser",
      getListTag: "getListTag",
      updateNoteById: "updateNoteById",
      createNewTag: "createNewTag",
      getListNote: "getListNote",
      getListNoteShopSP: "getListNoteShopSP",
    }),
    getListNoteShopContents(page) {
      if (page === undefined) {
        page = this.page;
      }
      const dataRequest = {
        page: page,
        data: {
          shop_id: this.shop_id,
          limit: this.limit,
        },
      };
      this.getListNoteShopContent(dataRequest);
    },
    deleteNote(id) {
      const formData = {
        id: id,
        shop_id: this.shop_id,
      };
      this.deleteNote(formData);
    },
    showConfirmEdit(id) {
      this.note = {};
      this.selectedTag = [];
      const shop_id = this.shop_id;
      this.getListTag(shop_id);
      // this.getNoteById(id);
      this.getNoteByIdUser(id);
      this.$store.commit("set", ["idNoteLog", id]);
      this.$bvModal.show("modal-edit-note");
    },
    showConfirm(id) {
      this.tag = "";
      this.dataModal = id;
    },
    async removeNoteContent(id) {
      const data = {
        id: id,
        shop_id: this.shop_id,
      };
      await this.deleteNoteContent(data);
      this.listNoteShopByContent.data.forEach((element, index) => {
        if (element.id === id) {
          this.listNoteShopByContent.data.splice(index, 1);
        }
      });
      this.$bvModal.hide("delete-modal-page");
    },
    addTag(item) {
      if (this.selectedTag.length === 0) {
        this.selectedTag.push({
          tag_name: item.tag_name,
          tag_id: item.tag_id,
          checked: true,
        });
      } else {
        this.selectedTag.map((value) => {
          if (value.tag_id === item.tag_id) {
            this.isAdded++;
          }
        });
        if (this.isAdded === 0) {
          this.selectedTag.push({
            tag_name: item.tag_name,
            tag_id: item.tag_id,
            checked: true,
          });
          this.isAdded = 0;
        } else {
          this.isAdded = 0;
        }
      }
    },
    removeTag(event) {
      this.index = this.selectedTag.indexOf(event.target);
      this.selectedTag.splice(event, 1);
    },
    async editNote() {
      if (this.noteById.title_note === "") {
        this.$toasted.error("ノートタイトルは入力必須項目です。");
      } else if (this.noteById.content_note == "") {
        this.$toasted.error("コンテンツノートは入力必須項目です。");
      } else {
        this.isLoading = true;
        let shop_id = this.shop_id;
        const listtag = this.selectedTag.map((item) => Number(item.tag_id));
        const formData = {
          shop_id: shop_id,
          id: this.noteById.note_id,
          content_id: this.noteById.content_id,
          page_id: this.noteById.page_id,
          tag_id: listtag,
          title_note: this.noteById.title_note,
          content_note: this.noteById.content_note,
          page_pdf: this.noteById.page_pdf,
          seconds_watch_video: this.noteById.seconds_watch_video,
          status: 0,
        };
        const request = await this.$store.dispatch("updateNoteById", formData);
        if (request.success === true) {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-edit-note");
            this.isLoading = false;
          });
          this.showDetailContent(this.idContent);
          // this.getListNote(this.IdContent);
          const dataRequest = {
            page: this.page,
            data: {
              shop_id: this.shop_id,
              limit: this.limit,
            },
          };
          this.getListNoteShopContent(dataRequest);
          const dataRequestSP = {
            page: this.pageSP,
            data: {
              shop_id: this.shop_id,
              limit: this.limit,
            },
          };
          this.getListNoteShopSP(dataRequestSP);
        } else {
          this.isLoading = false;
          this.$toasted.error(request.message);
        }
      }
    },
    async createTag() {
      if (this.tag === "") {
        this.$toasted.error("入力してからEnterを押してください");
      } else {
        const formData = {
          user_id: localStorage.getItem("user_id"),
          shop_id: this.shop_id,
          tag_name: this.tag,
        };
        await this.createNewTag(formData);
        await this.getListTag(this.shop_id);
        const datatag = this.listTagOptions[0];
        this.$set(datatag, "checked", true);
        this.addTag(datatag);
        this.tag = "";
      }
    },
    ClickTagChecked(item) {
      if (item.checked === true) {
        this.$set(item, "checked", false);
        this.selectedTag = this.selectedTag.filter(
          (ele) => ele.tag_id !== item.tag_id
        );
      } else {
        this.$set(item, "checked", true);
        this.selectedTag.push({
          tag_name: item.tag_name,
          tag_id: item.tag_id,
        });
      }
    },
    closeModalEdit() {
      this.$bvModal.hide("modal-edit-note");
      this.selectedTag = [];
      this.note = {};
      this.$store.commit("set", ["idNoteLog", null]);
    },
    async showDetailContent(id) {
      this.idContent = id;
      const dataReq = {
        page: 1,
        data: {
          id: id,
          shop_id: this.shop_id,
          limit: this.limit,
        },
      };
      // const request = await this.$store.dispatch(
      //   "getListNoteShopByIdContent",
      //   dataReq
      // );
      await Api.userRequestServer
        .post(
          `/${Urls.NOTE_CONTENT}/${Urls.SHOW}/${dataReq.data.id}?${Urls.PAGE}=${dataReq.page}`,
          dataReq.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            this.listNoteShopByContent = data.data;
            this.isShowDetailContent = 1;
          } else {
            this.isShowDetailContent = 0;
          }
        })
        .catch(() => {
          // console.log(error);
        });
      // if (request.success === true) {
      //   this.listNoteShopByContent = request.data;
      //   this.isShowDetailContent = 1;
      // } else {
      //   this.isShowDetailContent = 0;
      // }
    },
    backListContent() {
      this.getListNoteShopContents(this.page);
      this.isShowDetailContent = 0;
    },
    showDetailContentMobile(item) {
      window.scrollTo(0, 0);
      this.$store.commit("set", ["noteByContent", item]);
      this.$store.commit("set", ["IdContent", item.id]);
      this.$store.commit("set", ["isShowButton", !this.isShowButton]);
      this.$store.commit("set", ["isShowNote", !this.isShowButton]);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-note {
  padding: 0 50px;
  @media (max-width: 767px) {
    padding: 0 25px;
  }
  .dc-header {
    padding-top: 30px;
    font-size: 14px;
    a {
      color: #000;
      font-weight: 500;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &-title {
    font-size: 24px;
    font-weight: 600;
  }
  &-content {
    margin-top: 30px;
    .title-content {
      font-size: 22px;
      color: #4a4a4a;
      margin: 10px 0;
    }
    .title-content-list {
      &-item {
        border: 2px solid #4a4a4a;
        border-radius: 20px;
        padding: 20px;
        margin-bottom: 30px;
        .header-item {
          padding: 0 50px 0 30px;
          &-title {
            font-size: 20px;
            color: #4a4a4a;
            font-weight: 600;
          }
          &-time {
            font-size: 16px;
            color: #000;
          }
        }
        .content-item {
          padding: 0 50px;
          margin: 20px 0;
          font-size: 14px;
        }
        .list-tags {
          &-item {
            border: 2px solid #af5a21;
            background: #ed7d30;
            color: white;
            padding: 5px 20px;
            border-radius: 10px;
            margin-left: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
.list-tag {
  .item-tag {
    border: 2px solid #af5a21;
    background: #ed7d30;
    color: white;
    padding: 5px 20px;
    border-radius: 10px;
    margin-left: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }
}
.customA {
  background: #eee !important;
  border: 1px solid #eee !important;
  border-right: none !important;
  color: #1d1d1d !important;
  transition: all 0.3s ease !important;
}
.customA :hover {
  background: #eee;
  border-right: none;
  color: #1d1d1d;
  transition: all 0.3s ease;
  cursor: pointer;
}
.customA ::before {
  content: "";
  display: block;
  width: 18px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 18px 20px;
  position: absolute;
  top: -1px;
  left: 100%;
  transition: 0.2s ease-out;
}
.customSpan {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 235px;
  display: block;
  height: 18px;
  line-height: 18px;
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ",
    Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  word-break: break-all;
  box-sizing: border-box;
  font-size: 11px;
  padding: 0 0 0 10px;
}
.customContentNote {
  width: 95%;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.customTag2 {
  background: #fff;
  border: 1px solid #eee;
  border-right: none;
  color: #1d1d1d;
  transition: all 0.3s ease;
}
.customTitle {
  cursor: pointer;
  border-bottom: 1px solid #d1d1d1;
  padding: 30px 0 30px 20px;
  background-image: url(../../assets/img/icon_arrow01.png);
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 9px;
}
.customTitleMoblie {
  cursor: pointer;
  border: 1px solid #d1d1d1;
  padding: 15px 0 15px 15px;
  /* background-image: url(/img/icon_arrow01.939c2d50.png); */
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 9px;
  border-radius: 20px;
  box-shadow: 0px 0px 11px 0px rgba(125, 125, 125, 0.75);
  -webkit-box-shadow: 0px 0px 11px 0px rgba(125, 125, 125, 0.75);
  -moz-box-shadow: 0px 0px 11px 0px rgba(125, 125, 125, 0.75);
}
.customBorder {
}

html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}
.hoverMouse:hover {
  border: 1px solid #ccc !important;
}
.hoverMouse:focus {
  border: 1px solid #ccc !important;
  box-shadow: none;
}
.title2 {
  color: #7f7f7f;
  width: 100%;
  text-align: center;
  font-size: 16px;
  margin-top: 2em !important;
  margin-bottom: 2em !important;
  display: flex;
  align-items: center;
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ",
    Meiryo, "ＭＳ Ｐゴシック", sans-serif;
}
.title2::before,
.title2::after {
  border-top: 1px solid;
  content: "";
  flex-grow: 1;
  color: #dfdadadb;
}
.title2:before {
  margin-right: 2rem;
}
.title2:after {
  margin-left: 2rem;
}
>>> .modalCustom > .modal-dialog > .modal-content > .modal-body {
  height: 465px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-top: 1px solid;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  border-color: #d8dbe0;
}
>>> .modalCustom > .modal-dialog > .modal-content > .modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  border-top: 1px solid;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  border-color: #d8dbe0;
}
@media (max-width: 767px) {
  >>> .modalCustom > .modal-dialog {
    max-width: 720px !important;
    width: 100% !important;
    margin: 1.75rem auto;
  }
  >>> .modalCustom > .modal-dialog > .modal-content > .modal-body {
    width: 100%;
    overflow: auto;
    height: 400px;
  }
}
.font-title-note {
  font-weight: 500;
  font-size: 15px;
}
.title-note-content {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
